/**
 * Custom Error Page Layout Styles
 * @author Charles Harwood
 */

.error-page {

  // Hero.
  .carousel.carousel-hero {
    .field-slideimage {
      height: 40vw;
      min-height: 420px;
      max-height: 700px;
    }

    .field-slidetext {
      max-width: 600px;
    }
  }

  // Styles specific to the 500 server error page.
  &.error-page--500 {
    // Hide unnecessary global elements.
    .component.toggle,
    .navigation,
    .footer-row-1 {
      display: none;
    }

    // Footer styles.
    footer {
      padding-top: 0;

      .footer-row-2 {
        margin-top: 0;
        border-top: 0;
      }

      .footer-copyright-wrapper {
        justify-content: flex-end;

        > .component {
          flex: 0 0 auto;
        }
      }

      .link-list {
        display: none;
      }

      .social-links {
        padding-left: 72px;
      }
    }
  }
}
